export function PRIVACY_POLICY_TITLES() {
    return ({
        en: {
            title_1: '',
            title_2: 'Data Privacy',
            title_3: 'Data Security & Non-Disclosure',
        },
        jp: {
            title_1: '',
            title_2: 'Data Privacy',
            title_3: 'Data Security & Non-Disclosure',
        }
    });
}

export function PRIVACY_POLICY_TEXT() {
    return ({
        en: {
            text_1 : `
                Sakura Software Solutions is committed to protecting our users’ Confidential Information.
                <i><b>Users</i></b> in the context of this policy means both employees and their employers.
                <i><b>Confidential Information</i></b> shall mean all information related to any aspect of the 
                business of our users which is either information not known by actual or potential competitors 
                of the users or is proprietary information of the users, whether of a technical nature or otherwise.
            `,
            text_2 : `
                We will hold all Confidential Information or data in confidence and will not disclose, use, copy, 
                publish, summarize, or remove from the 3S STAR tool any Confidential Information, except when necessary 
                to setup, use, improve the application as required by the user providing such information.
                <br/>
                <br/>
                We will ensure that any user of the our software is only able to gain access to such information that said 
                user has provided, and not for any other users. This means that any information provided by any user will 
                only be available – within the tool – for the user providing it.
            `,
            text_3 : `
                We will be happy to engage with all our users on all matters related to data management and security, and 
                agree to the necessary agreements related to information non-disclosure.
            `,
            text_4 : `
                If you have any questions or suggestions, please feel free to contact us at info@sakurasoftsolutions.com.
            `
        },
        jp: {
            text_1 : `
                最終更新日：2021年4月19日
                <br/>
                <br/>
                Sakura Software Solutions（以下「弊社」）は、ユーザーの機密情報を保護することに努めています。本ポリシーにおける「ユーザー」とは、従業員及びその雇用主の双方を指します。「機密情報」とは、ユーザーの事業に関連するあらゆる情報であり、実際または潜在的な競合他社に知られていない情報、または技術的なものであるか否かにかかわらず、ユーザー固有の情報を指します。
            `,
            text_2 : `
                弊社は、全ての機密情報やデータを機密として保持し、提供者であるユーザーの要件に基づいてアプリケーションのセットアップ、利用、または改善が必要な場合を除き、3S STARツールからその機密情報を開示、使用、複製、公開、要約、または削除しません。
                <br/>
                <br/>
                弊社は、弊社のソフトウェアのユーザーが、自身が提供した情報にのみアクセスできるようにし、他のユーザーの情報にはアクセスできないことを保証します。つまり、ユーザーが提供した情報は、ツール内でその提供者であるユーザーだけが利用できるようにします。 
            `,
            text_3 : `
                弊社は、データ管理に関する全ての事項について、ユーザーの皆様と積極的に連携し、情報の非開示に関する必要な契約に合意する用意があります。
            `,
            text_4 : `
                ご質問やご提案がございましたら、ご遠慮なく info@sakurasoftsolutions.com までご連絡下さい。
            `
        }
    });
}

