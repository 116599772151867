export function TOS_TITLES() {
    return ({
        en: {
            title_1: '',
            title_2: 'Restricted Access of the Service',
            title_3: 'Account Registration & Administration',
            title_4: 'Customer Data',
            title_5: 'Intellectual Property Rights',
            title_6: 'Free Trial Service',
            title_7: 'Changes to Terms',
            title_8: '',
        },
        jp: {
            title_1: '',
            title_2: 'サービスへのアクセス制限',
            title_3: 'アカウント登録と管理',
            title_4: '顧客データ',
            title_5: '知的財産権',
            title_6: '無料トライアルサービス',
            title_7: '規約の変更',
            title_8: '',
        }
    });
}

export function TOS_TEXT(privacyPolicyPath, overviewPath) {
    return ({
        en: {
            text_1 : `
                Please read these Terms of Use (<i>Terms</i>, <i>Terms of Use</i>) carefully before using this 
                website and our STAR tool (the <i>Service</i>) operated by Sakura 
                Software Solutions (3S) LLC (<i>us</i>, <i>we</i>, or <i>our</i>). 
                <br/>
                <br/>     
                Your access to and use of the Service is conditioned on your acceptance of and compliance with 
                these Terms. These Terms apply to all visitors, users and others who access or use the Service. 
                <br/>
                <br/>     
                <b>By accessing or using the Service you agree to be bound by these Terms and accept our 
                separate <a target="_blank" href="${privacyPolicyPath}"> 3S Privacy Policy</a>. If you do 
                not accept both these Terms of Use and the Privacy Policy, you may not access or use the 
                Website, and you must immediately cease accessing or using the Website.</b>
            `,
            text_2 : `
                We may in the future need to restrict or suspend access to parts (or all) of our Service immediately, 
                without prior notice or liability, for any reason whatsoever, including without limitation if you breach 
                the Terms. However, if we make any material change in the core functionality of the Service, then 
                we will make sure to alert the user by sharing an announcement on our Release Notes page via our 
                webpage and/or by sending you an email. 
            `,
            text_3 : `
                To initially register to the Service, you shall create a new account for the Service.  By creating an 
                account (“Account”) and registering to the Service you become, either individually or represent your 
                employer or any entity, on behalf of whom you created the Account, a 3S customer (the 
                <i>Customer</i>). The first user of the Account is automatically assigned as the Account administrator 
                (the <i>Admin</i>).  An Admin will have the ability to assign or add-on other users to the Account, who
                will have access to control the use of the Service and their customer data.
            `,
            text_4 : `
                Customer Data is any data, personal information, or any additional content, that is uploaded, 
                submitted, or made available to the Service by you and or any user and is handled by us on our 
                customer’s behalf.  Customers will retain all rights and control to their customer data in any forms 
                submitted to our service. By using our service, the customer grants us limited access to process, 
                and display the customer data in the Service, to address any technical support issues, and to apply 
                customization requests.  
            `,
            text_5 : `
                Unless otherwise noted, all materials including without limitation, logos, brand names, images, 
                designs, copyrights, trademarks, patents and other intellectual property rights in and on our website 
                and all content and software located on the site shall remain the sole property of or its licensors. 
                The use of our trademarks, content and intellectual property is forbidden without the express written 
                consent from Sakura Software Solutions LLC. 
                <br/>
                <br/>
                You must not:
                <ul>
                    <li>Republish material from our website without prior written consent.</li>
                    <li>Sell or rent material from our website.</li>
                    <li>Reproduce, duplicate, create derivative, copy or otherwise exploit material on our website for any purpose.</li>
                    <li>Redistribute any content from our website, including onto another website.</li>
                </ul>
            `,
            text_6 : `
                As a part of our Service, we offer a free, no-commitment trial (<i>Trial Service</i>).  All information 
                regarding the Trial Service will be communicated to the user via email or within the Service.  We 
                reserve the right to modify, terminate, and/or limit the Trial Service immediately, without prior notice 
                or liability, for any reason whatsoever, including without limitation if you breach the Terms. 
            `,
            text_7 : `
                We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a 
                revision is material, we will try to provide at least 30 days' notice prior to any new terms taking 
                effect. What constitutes a material change will be determined at our sole discretion.  
            `,
            text_8 : `
                If you have any questions about these Terms, please contact us at <b>info@sakurasoftsolutions.com</b>.
            `,  
        },
        jp: {
            text_1 : `
                最終更新日: 2021年5月20日
                <br/>
                <br/> 
                Sakura Software Solutions (3S) をご利用頂き、ありがとうございます。
                <br/>
                <br/>
                本利用規約（以下、「規約」）を注意深くお読み下さい。この規約は、Sakura Software Solutions LLC（以下「私たち」または「弊社」）が運営するウェブサイト (<a target="_blank" href="${overviewPath}">https://sakurasoftsolutions.com</a>) 及び弊社のSTARツール（以下「サービス」）のご利用に適用されます。
                <br/>
                <br/>
                サービスへのアクセス及びご利用は、これらの規約に同意し、遵守することを条件とします。この規約は、サービスにアクセスまたは利用する全ての訪問者、ユーザー、及びその他の方々に適用されます。
                <br/>
                <br/>
                <b>サービスにアクセスまたはご利用することにより、あなたはこれらの規約に拘束されることに同意し、弊社のウェブサイト <a target="_blank" href="${privacyPolicyPath}">https://sakurasoftsolutions.com/privacy-policy</a> で確認できる「3Sプライバシーポリシー」に同意したものとみなされます。利用規約及びプライバシーポリシーの両方に同意しない場合、ウェブサイトへのアクセスやご利用はできず、直ちにアクセス及び利用を中止する必要があります。</b>
            `,
            text_2 : `
                将来的に、何らかの理由により、予告なしに、また弊社が責任を負うことなく、サービスの一部または全体へのアクセスを即時に制限または停止する必要が生じる場合があります（これには、利用規約の違反を含みますが、それに限定されません）。但し、サービスの中核的な機能に重大な変更を加える場合は、弊社のウェブページ上のリリースノートページでのお知らせ、またはメールによる通知を通じて、ユーザーに確実に告知します。
            `,
            text_3 : `
                サービスを初めて登録する際には、新しいアカウントを作成する必要があります。アカウント（以下「アカウント」）を作成し、サービスに登録することにより、個人として、またはアカウントを作成した雇用主やその他の法人を代表して、3Sの顧客（以下「顧客」）となります。アカウントの最初のユーザーは、自動的にアカウント管理者（以下「管理者」）として割り当てられます。管理者は、他のユーザーをアカウントに追加または割り当てることができ、サービスの利用及び顧客データの管理にアクセスできる権限を持ちます。
            `,
            text_4 : `
                顧客データとは、あなたまたは他のユーザーがサービスにアップロード、提出、または提供したデータ、個人情報、その他のコンテンツを指し、弊社が顧客を代表して取り扱うものです。顧客は、弊社のサービスに提供されたあらゆる形式のデータに関する権利と管理を完全に保持します。サービスを利用することにより、顧客は弊社に対し、技術サポートの問題に対応し、カスタマイズ要求を適用するために、顧客データを処理し、サービス内で表示する限定的な権限を付与するものとします。
            `,
            text_5 : `
                特に明記されていない限り、弊社のウェブサイト上及びその中にあるロゴ、ブランド名、画像、デザイン、著作権、商標、特許、その他の知的財産権を含む全ての資料、コンテンツ、及びソフトウェアは、Sakura Software Solutions LLCまたはそのライセンサーの独占的な所有物です。弊社の商標、コンテンツ、知的財産を使用することは、Sakura Software Solutions LLCの書面による明示的な同意がない限り、禁止されています。
                <br/>
                <br/>
                以下の行為は禁止されています：
                <ul>
                    <li>弊社のウェブサイトの資料を、事前の書面による同意なしに再公開すること。</li>
                    <li>弊社のウェブサイトの資料を販売または賃貸すること。</li>
                    <li>弊社のウェブサイトの資料をいかなる目的でも複製、複写、派生物の作成、コピー、またはその他の方法で利用すること。</li>
                    <li>弊社のウェブサイトのコンテンツを、他のウェブサイトを含むいかなる場所にも再配布すること。</li>
                </ul>
            `,
            text_6 : `
                弊社のサービスの一環として、無料で契約義務のないトライアルサービス（以下「トライアルサービス」）を提供しています。トライアルサービスに関する全ての情報は、メールまたはサービス内を通じてユーザーに通知されます。弊社は、いかなる理由においても、利用規約に違反した場合を含め、事前の通知や責任を負うことなく、トライアルサービスを変更、終了、または制限する権利を有します。
            `,
            text_7 : `
                弊社は、独自の裁量により、いつでもこれらの規約を変更または置き換える権利を有します。重大な改訂が行われる場合、新しい規約が発効する少なくとも30日前に通知を行うよう努めます。何が重大な変更に該当するかは、弊社の独自の判断で決定されます。
            `,
            text_8 : `
                これらの規約に関してご質問がある場合は、info@sakurasoftsolutions.com までお問合せ下さい。
            `
        }
    });
}

