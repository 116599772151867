import React from 'react';
import { useState } from 'react';

import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import styled from '@emotion/styled';

import PageTitle from '../../common/headings/PageTitle';
import TextSection from './TextSection';
import { primaryLightest, primaryDark } from '../../constants/colors'; 
import { privacyPolicyPath, overviewPath } from '../../constants/routes';


import LanguageSelector from '../documentation/LanguageSelector';
import { TOS_TEXT, TOS_TITLES } from './TermsOfServiceText';
 
 
const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 920,
    margin: '0 auto',
    marginBottom: 96,
    padding: '12px 12px',
    paddingBottom: 36,
  }
}));

export default function PrivacyPolicyView(props) {
  const classes = useStyles();
  
  const { setDocumentTitle } = props;
  const [language, setLanguage] = useState('en');
  const TOS = TOS_TEXT(privacyPolicyPath, overviewPath);
  const TOS_TITLE = TOS_TITLES();

  const LanguageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 1080px;
    width: 100%;
    margin-bottom: 0px;
  `;
  const languageSelector = (
    <LanguageSelector
      selectedFile={language}
      enFile={'en'}
      jpFile={'jp'}
      onChange={(e) => setLanguage(e.target.value)}
    />
  );

  React.useEffect(() => setDocumentTitle('3S | Terms of Service'))

  return (
    <React.Fragment>
      <PageTitle>Terms of Service</PageTitle>

      <Card className={classes.card}>
        <LanguageContainer>
          {languageSelector}
        </LanguageContainer>
        
        <TextSection text={TOS[language].text_1} />
        <TextSection text={TOS[language].text_2} title={TOS_TITLE[language].title_2} color={primaryLightest} borderColor='silver' />
        <br/>
        <TextSection text={TOS[language].text_3} title={TOS_TITLE[language].title_3} color={primaryLightest} borderColor='silver' />
        <br/>
        <TextSection text={TOS[language].text_4} title={TOS_TITLE[language].title_4} color={primaryLightest} borderColor='silver' />
        <br/>
        <TextSection text={TOS[language].text_5} title={TOS_TITLE[language].title_5} color={primaryLightest} borderColor='silver' />
        <br/>
        <TextSection text={TOS[language].text_6} title={TOS_TITLE[language].title_6} color={primaryLightest} borderColor='silver' />
        <br/>
        <TextSection text={TOS[language].text_7} title={TOS_TITLE[language].title_7} color={primaryLightest} borderColor='silver' />
        <br/>
        <TextSection text={TOS[language].text_8} />
      </Card>
    </React.Fragment>
  )
}


