import React from 'react';
import { useState } from 'react';

import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import styled from '@emotion/styled';

import PageTitle from '../../common/headings/PageTitle';
import TextSection from './TextSection';
import { primaryLightest, primaryDark } from '../../constants/colors'; 

import LanguageSelector from '../documentation/LanguageSelector';
import { PRIVACY_POLICY_TEXT, PRIVACY_POLICY_TITLES } from './PrivacyPolicyViewText';

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 920,
    margin: '0 auto',
    marginBottom: 96,
    padding: '12px 12px',
    paddingBottom: 36,
  },
}));

export default function PrivacyPolicyView(props) {
  const classes = useStyles();
  
  const { setDocumentTitle } = props;
  const [language, setLanguage] = useState('en');
  const PRIVACY_POLICY = PRIVACY_POLICY_TEXT();
  const PRIVACY_POLICY_TITLE = PRIVACY_POLICY_TITLES();

  const LanguageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 1080px;
    width: 100%;
    margin-bottom: 0px;
  `;
  const languageSelector = (
    <LanguageSelector
      selectedFile={language}
      enFile={'en'}
      jpFile={'jp'}
      onChange={(e) => setLanguage(e.target.value)}
    />
  );
  
  React.useEffect(() => setDocumentTitle('3S | Privacy Policy'))

  return (
    <React.Fragment>
      <PageTitle>Privacy Policy</PageTitle>

      <Card className={classes.card}>
        <LanguageContainer>
          {languageSelector}
        </LanguageContainer>

        <TextSection text={PRIVACY_POLICY[language].text_1} />
        <TextSection text={PRIVACY_POLICY[language].text_2} title={PRIVACY_POLICY_TITLE[language].title_2} color={primaryLightest} borderColor='silver' />
        <br/>
        <TextSection text={PRIVACY_POLICY[language].text_3} title={PRIVACY_POLICY_TITLE[language].title_3} color={primaryLightest} borderColor='silver' />
        <br/>
        <TextSection text={PRIVACY_POLICY[language].text_4} />
      </Card>
    </React.Fragment>
  )
}


